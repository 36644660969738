import Swiper, { Navigation, Pagination, Lazy, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
Swiper.use([Navigation, Pagination, A11y]);
class ResourceSlider {
	constructor() {
		this.resourceWrapper = document.querySelector(".resource-slider");
		this.slider = null;
		this.swiperInstance = null;
		if (this.resourceWrapper) {
			this.run();
		}
	}

	run() {
		this.sliderEl = this.resourceWrapper.querySelector(".swiper");

		this.swiperInstance = new Swiper(this.sliderEl, {
			spaceBetween: 25,
			direction: "horizontal",
			slidesPerView: 1,
			slidesPerGroup: 1,
			grabCursor: true,
			loop: true,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			pagination: {
				el: ".swiper-pagination",
				type: "bullets",
				clickable: true,
			},
		});
	}
}

export default ResourceSlider;
