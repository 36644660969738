import { LitElement } from "lit";

class CustomSearch extends LitElement {
	constructor() {
		super();
		this.searchTerm = "";
		this.searchModifiers = "";
		this.submitButton = null;
		this.externalSubmit = null;
		this.input = "";
	}

	connectedCallback() {
		super.connectedCallback();
		this.input = this.querySelector("input");
		this.submitButton = this.querySelector("button");
		this.searchTerm = this.input.value;
		this.externalSubmit = this.querySelector(".fancy-btn");

		this.watchForSearch();
		this.checkForURLSearchTerm();
	}

	watchForSearch() {
		this.input.addEventListener("keyup", (e) => {
			if (e.keyCode === 13) {
				this.submitButton.click();
			}
		});

		// When submitButton is clicked submitForm()
		this.submitButton.addEventListener("click", () => {
			this.submitForm();
		});
		this.externalSubmit.addEventListener("click", () => {
			this.submitForm();
		});
	}

	submitForm() {
		const filterSelector = document.querySelector("filter-selector");

		// Ask the filterSelector for any selected filters
		if (filterSelector) {
			this.searchModifiers = filterSelector.getFilterQuery();
		}

		// Wordpress freaks out if the search term is empty
		if (this.input.value === "") {
			this.input.value = " ";
		}

		// Check for essential-action query string
		const searchQuery = window.location.search;

		console.log(this.searchModifiers);

		// navigate to search page
		window.location.href = "/search/" + encodeURIComponent(this.input.value) + "?" + this.searchModifiers;
	}

	checkForURLSearchTerm() {
		// The search term is the first part of the url after the /search/ not followed by ?
		const url = window.location.pathname;
		//console.log(url);
		let urlParts = url.split("/");
		// remove empty strings from urlParts
		urlParts = urlParts.filter((part) => part !== "");
		//console.log(urlParts);
		if (urlParts.length > 1) {
			if (urlParts[1].charAt(0) !== "?") {
				this.input.value = decodeURIComponent(urlParts[1]);
			}
		}
	}

	createRenderRoot() {
		return this;
	}
}

export default CustomSearch;
