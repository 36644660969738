import { LitElement } from "lit";

class FancyDots extends LitElement {
	constructor() {
		super();
		this.dots = [];
		this.activeDots = [];
		this.rows = null;
		this.cols = null;
		this.dotsNeeded = null;
		this.deactiveThreshold = null;
		this.fancyDotBus = null;
		this.colors = ["blue", "dark-blue", "red", "dark-red", "gray", "dark-gray"];
		this.dotEl = null;
	}

	connectedCallback() {
		super.connectedCallback();
		this.fancyDotBus = document.querySelector("fancy-dot-bus");
		if (!this.fancyDotBus) {
			console.log("FancyDotBus not found");
			this.fancyDotBus = document.createElement("fancy-dot-bus");
			document.body.appendChild(this.fancyDotBus);
		}
		this.createDotEl();
		this.setupDots();
	}

	firstUpdated(_changedProperties) {
		super.firstUpdated(_changedProperties);
		this.fancyDotBus.attach(this);
	}

	/**
	 * Adds in dot inner elements
	 */
	setupDots() {
		this.dots = [];
		this.activeDots = [];
		const styles = window.getComputedStyle(this);
		this.rows = styles.getPropertyValue("grid-template-rows").split(" ");
		this.cols = styles.getPropertyValue("grid-template-columns").split(" ");
		this.dotsNeeded = this.rows.length * this.cols.length;
		this.deactiveThreshold = this.dotsNeeded * 0.5;

		for (let i = 0; i < this.dotsNeeded; i++) {
			this.dots.push(this.dotEl.cloneNode(true));
		}
	}

	animateDots() {
		const randomIndex = Math.floor(Math.random() * this.dots.length);
		const randomDot = this.dots[randomIndex];
		const randomColor = this.colors[Math.floor(Math.random() * this.colors.length)];
		if (!randomDot.hasAttribute("class")) {
			randomDot.classList.add(randomColor);
		}
		randomDot.setAttribute("active", "");
		this.activeDots.push(randomDot);
		if (this.activeDots.length > this.deactiveThreshold) {
			this.activeDots[0].removeAttribute("active");
			this.activeDots[0].removeAttribute("class");
			this.activeDots[0].classList.add(randomColor);
			this.activeDots.splice(0, 1);
		}
	}
	createDotEl() {
		this.dotEl = document.createElement("figure");
		this.dotEl.setAttribute("data-dot", "");
	}

	createRenderRoot() {
		return this;
	}

	render() {
		return this.dots.forEach((dot) => {
			this.appendChild(dot);
		});
	}
}

class FancyDotBus extends LitElement {
	constructor() {
		super();
		this.fancyDots = [];
		this.isAnimating = false;
		this.internalRef = null;
		this.internalDuration = 350;
	}

	connectedCallback() {
		super.connectedCallback();
	}

	attach(fancyDot) {
		this.fancyDots.push(fancyDot);
		this.runAnimation();
	}

	runAnimation() {
		if (!this.isAnimating) {
			this.isAnimating = true;

			this.intervalRef = setInterval(() => {
				this.fancyDots.forEach((fancyDot) => {
					fancyDot.animateDots();
				});
			}, this.internalDuration);
		}
	}
}

customElements.define("fancy-dots", FancyDots);
customElements.define("fancy-dot-bus", FancyDotBus);
