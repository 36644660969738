import { LitElement, html } from "lit";

class FilterSelector extends LitElement {
	constructor() {
		super();
		this.buttons = null;
		this.originalSelection = [];
		this.clickedButtons = [];
		this.hiddenButton = this.querySelector(".hidden-button");
		this.updateFilterButton = this.querySelector(".update-filter-button");
		this.clearFilterButton = this.querySelector(".clear-filter-button");
		this.exitButton =
			'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.3 17.3"><g data-name="Icon ionic-ios-close-circle-outline" fill="currentColor"><path data-name="Path 485" d="M11.7 10.8 9.6 8.6l2.1-2.2a.7.7 0 0 0-.9-.9L8.6 7.7 6.4 5.5a.7.7 0 1 0-.9 1l2.2 2.1-2.2 2.2a.6.6 0 0 0 0 1 .7.7 0 0 0 1 0l2.1-2.2 2.2 2.1a.7.7 0 0 0 1 0 .7.7 0 0 0 0-.9Z"/><path data-name="Path 486" d="M8.6 1.2a7.5 7.5 0 1 1-5.3 2.1 7.4 7.4 0 0 1 5.3-2.1m0-1.2a8.6 8.6 0 1 0 8.7 8.6A8.6 8.6 0 0 0 8.6 0Z"/></g></svg>';
		this.authorFilter = this.querySelector(".author-filter");
		this.authorFilterValue = '';
	}

	connectedCallback() {
		super.connectedCallback();
		this.buttons = this.querySelectorAll(".filter-button");
		this.setupButtons();
		this.checkUrlForFilterQuery();
		const isTouch = "ontouchstart" in window;
		document.documentElement.className += isTouch ? " touch " : " no-touch ";

		if (this.updateFilterButton) {
			this.updateFilterButton.addEventListener("click", (event) => {
				event.preventDefault();
				document.querySelector("custom-search").submitForm();
			});
		}

		if (this.clickedButtons.length > 0) {
			this.updateFilterButton.classList.remove("hidden");
		}

		this.setMiniCategories();

		this.authorFilter.addEventListener("change", (event) => {
			console.log("we got here");
			console.log(event);

			this.authorFilterChanged(event);
		});
	}

	setupButtons() {
		//console.log(this.querySelectorAll(".filter-accordion"));

		this.buttons.forEach((button) => {
			button.addEventListener("click", () => {
				if (button.classList.contains("selected")) {
					this.unselectButton(button);
				} else {
					this.selectButton(button);
				}
			});
		});

		if (this.clearFilterButton) {
			this.clearFilterButton.addEventListener("click", () => {
				this.clickedButtons = [];
				document.querySelector("custom-search").submitForm();
			});
		}
	}

	selectButton(button, checkQuery = true) {
		button.classList.add("selected");
		this.removeTypeDuplicates(button, button.dataset.group);
		this.clickedButtons.push(button);

		if (this.checkForChangedQuery() && checkQuery) {
			this.updateFilterButton.classList.remove("hidden");
		} else {
			this.updateFilterButton.classList.add("hidden");
		}

		if (this.clickedButtons.length > 0) {
			this.clearFilterButton.classList.remove("hidden");
		}
	}

	unselectButton(button) {
		button.classList.remove("selected");
		this.clickedButtons.splice(this.clickedButtons.indexOf(button), 1);

		button.blur();

		if (this.checkForChangedQuery()) {
			this.updateFilterButton.classList.remove("hidden");
		} else {
			this.updateFilterButton.classList.add("hidden");
		}

		console.log(this.clickedButtons);
	}

	/**
	 * Removes duplicate types from the clicked buttons array
	 *
	 * @param button
	 * @param group
	 */
	removeTypeDuplicates(button, group) {
		this.clickedButtons.filter((matchedButton) => {
			if (group === matchedButton.dataset.group) {
				this.unselectButton(matchedButton);
				return false;
			} else {
				return true;
			}
		});
	}

	authorFilterChanged(event) {
		this.authorFilterValue = event.target.value;
		this.authorFilterButton = this.querySelector(".filter-button[data-group='resource_author']");

		if (this.authorFilterButton) {
			// Find existing resource author button in the clicked buttons array and remove
			this.clickedButtons.forEach((button) => {
				console.log("do we get here though?");
				if (button.dataset.group === "resource_author") {
					console.log("how about here???");
					button.dataset.slug = this.authorFilterValue;
					document.querySelector("custom-search").submitForm();
				}
			});

			this.authorFilterButton.dataset.slug = this.authorFilterValue;
			document.querySelector("custom-search").submitForm();
		} else {
			// Create a button and append it to the inner html of the filter
			const button = document.createElement("button");
			button.classList.add("filter-button");
			button.dataset.slug = this.authorFilterValue;
			button.dataset.group = "resource_author";
			this.clickedButtons.push(button);
			document.querySelector("custom-search").submitForm();
		}

	}

	getFilterQuery() {
		let query = "";
		this.clickedButtons.forEach((button) => {
			console.log(button);

			query += encodeURIComponent(button.dataset.group) + "=" + encodeURIComponent(button.dataset.slug) + "&";
		});

		query = query.slice(0, -1);

		return query;
	}

	checkForChangedQuery() {
		const containsAll = (arr1, arr2) => arr2.every((arr2Item) => arr1.includes(arr2Item));

		const sameMembers = (arr1, arr2) => containsAll(arr1, arr2) && containsAll(arr2, arr1);
		console.log(this.clickedButtons);
		console.log(this.originalSelection);
		return !sameMembers(this.originalSelection, this.clickedButtons);
	}

	getClickedButtons() {
		return this.clickedButtons;
	}

	setMiniCategories() {
		if (this.clickedButtons) {
			const wrapper = document.createElement("div");
			wrapper.classList.add("mini-categories");

			this.clickedButtons.forEach((button) => {
				const copyButton = button.cloneNode(true);
				copyButton.innerHTML += this.exitButton;
				copyButton.classList.remove("selected");
				copyButton.addEventListener("click", () => {
					// find the button in the clicked buttons array that has the same group as the button clicked
					const buttonToUnselect = this.clickedButtons.find((clickedButton) => {
						return clickedButton.dataset.group === button.dataset.group;
					});

					if (buttonToUnselect) {
						this.unselectButton(buttonToUnselect);
					}
					// remove the copyButton from the DOM
					wrapper.removeChild(copyButton);
					document.querySelector("custom-search").submitForm();
				});
				wrapper.appendChild(copyButton);
			});

			this.prepend(wrapper);
		}
	}

	createRenderRoot() {
		return this;
	}

	// check url for filter query
	// if query exists, select buttons
	// if query does not exist, unselect buttons
	checkUrlForFilterQuery() {
		const query = window.location.search.substring(1);
		//console.log(query);

		const queryArray = query.split("&");
		queryArray.forEach((query) => {
			const queryArray = query.split("=");
			const group = decodeURIComponent(queryArray[0]);
			const slug = decodeURIComponent(queryArray[1]);
			const button = this.querySelector(`button[data-group="${group}"][data-slug="${slug}"]`);
			if (button) {
				this.originalSelection.push(button);
				this.selectButton(button, false);
			}
		});
	}
}

export default FilterSelector;
